import 'regenerator-runtime/runtime'

import api from "@/services/apiService"
import Encrypt from "@/helpers/encrypt"

class ManageAccountService {

  /**
   * ดึงข้อมูลสร้างสมาชิกใหม่
   * @return {Object}
   */
  getTemplate() {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_BACKEND_API + 'account/template')
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      });
    });
  }

  /**
   * บันทึกตั้งค่าสมัครสมาชิก
   * @param  {Object} data
   * @return {Object}
   */
  saveTemplate(data) {
    return new Promise((resolve, reject) => {
      api.put(process.env.VUE_APP_BACKEND_API + 'account/template', data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      });
    });
  }

  /**
   * บันทึกเมมเบอร์
   * @param  {Object} data
   * @return {Object}
   */
  saveMember(data) {
    return new Promise((resolve, reject) => {
      api.post(process.env.VUE_APP_BACKEND_API + 'account/member', data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      });
    });
  }

  /**
   * บันทึกเอเย่นต์
   * @param  {Object} data
   * @return {Object}
   */
  saveAgent(data) {
    return new Promise((resolve, reject) => {
      api.post(process.env.VUE_APP_BACKEND_API + 'account/agent', data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      });
    });
  }

  /**
   * ดึงรายชื่อลูกทีมทั้งหมด Member + Agent
   * @return {Object}
   */
  getAccounts() {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_BACKEND_API + 'account')
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      });
    });
  }

  /**
   * ดึงรายชื่อลูกทีมของ agent
   * @param  {String} accountId [description]
   * @return {[type]}      [description]
   */
  loadChildAccount(accountId) {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_BACKEND_API + 'account/child/'+accountId)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      });
    });
  }

  /**
   * ดึงรายชื่อ Member
   * @return {Object}
   */
  getMembers() {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_BACKEND_API + 'account/member')
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      });
    });
  }

  /**
   * ดึงรายชื่อ Agent
   * @return {Object}
   */
  getAgents() {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_BACKEND_API + 'account/agent')
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      });
    });
  }

  /**
   * รายการบัญชีที่ถูกระงับ
   * @return {Object}
   */
  getBlockedAccounts() {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_BACKEND_API + 'account/blocked')
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      });
    });
  }

  /**
   * ดึงรายละเอียดบัญชี
   * @param  {String} id _id ของ account
   * @return {Object}
   */
  getAccount(id) {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_BACKEND_API + 'account/' + id)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      });
    });
  }

  /**
   * เปลี่ยนรหัสผ่านรหัสผู้ใช้บัญชีลูก
   * @param  {String} _id  user id
   * @param  {Object} data
   * @return {Object}
   */
  changePassword(_id, data) {
    return new Promise((resolve, reject) => {
      api.patch(process.env.VUE_APP_BACKEND_API + 'account/' + _id, data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      });
    });
  }

  /**
   * แก้ไขข้อมูลสมาชิก
   * @param  {String} _id  user id
   * @param  {Object} data
   * @return {Object}
   */
  updateProfile(_id, data) {
    return new Promise((resolve, reject) => {
      api.put(process.env.VUE_APP_BACKEND_API + 'account/' + _id, data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      });
    });
  }

  /**
   * ระงับบัญชี
   * @param  {String} _id account id
   * @param  {Object} data
   * @return {Object}
   */
  blockAccount(data) {
    return new Promise((resolve, reject) => {
      api.post(process.env.VUE_APP_BACKEND_API + 'account/block', data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      });
    });
  }

  /**
   * ยกเลิกระงับบัญชี
   * @param  {String} _id account id
   * @param  {Object} data
   * @return {Object}
   */
  unBlockAccount(data) {
    return new Promise((resolve, reject) => {
      api.post(process.env.VUE_APP_BACKEND_API + 'account/unblock', data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      });
    });
  }

  /**
   * เติมเงิน
   * @param  {String} id
   * @param  {Object} data.amount
   * @return {Object}
   */
  deposit(_id, data) {
    return new Promise((resolve, reject) => {
      api.post(process.env.VUE_APP_BACKEND_API + 'account/' + _id + '/deposit', data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      });
    });
  }

  /**
   * ถอนเงิน
   * @param  {String} id
   * @param  {Object} data.amount
   * @return {Object}
   */
  withdraw(_id, data) {
    return new Promise((resolve, reject) => {
      api.post(process.env.VUE_APP_BACKEND_API + 'account/' + _id + '/withdraw', data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      });
    });
  }

}

export default new ManageAccountService()
